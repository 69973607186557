import React, { useState } from 'react';
import { HashRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Typography, Alert, Stack } from '@trustyou/ty-brew/mui';

import config from './config';
import ContextsProvider from './contexts/contextsProvider';
import Login from './features/Login';
import ForgotPassword from './features/Login/ForgotPassword';
import ResetPassword from './features/Login/ResetPassword';
import { VogTrainings } from './features/Login/Trainings';
import Tyra from './features/Tyra';
import VERTICALS from './models/Verticals';
import { getCookie } from './utils';
import CXPSetPassword from './features/CXP/CXPSetPassword';
import CXPAlreadyUpgraded from './features/CXP/CXPAlreadyUpgraded';

const ProtectedRoutes = () => {
  const cookie = getCookie('sso_token');

  const [open, setOpen] = useState(true);
  const startDate = new Date('2025-03-19');
  const endDate = new Date('2025-03-28');
  const currentDate = new Date();
  const isVisible = currentDate >= startDate && currentDate <= endDate;
  return cookie ? (
    <>
      {open && isVisible && (
        <Stack sx={{ justifyContent: 'center', backgroundColor: 'rgb(229, 246, 253)' }}>
          <Alert icon={false} severity='info' sx={{ marginLeft: 23 }} onClose={() => setOpen(false)}>
            <Typography variant='body1'>
            Attention! There will be scheduled downtime due to maintenance on our servers from 26 March 2025 to 27 March 2025 10:00 pm until approximately 4:00 am (CET)
            </Typography>
          </Alert>
        </Stack>
      )}
      <Outlet />
    </>
  ) : (
    <Navigate to={`/auth/${config.vertical}/login`} state={{ previousHash: window.location.hash }} replace />
  );
};

const tagManagerArgs = {
  gtmId: config.gtm.containerId,
  auth: config.gtm.auth,
  preview: config.gtm.preview,
};

TagManager.initialize(tagManagerArgs);

const App = () => (
  <ContextsProvider>
    <HashRouter>
      <Routes>
        <Route path='auth/*'>
          <Route path={VERTICALS.HOTELS}>
            <Route path='login/:themeId?' element={<Login vertical={VERTICALS.HOTELS} />} />
            <Route path='forgot_password/:themeId?' element={<ForgotPassword vertical={VERTICALS.HOTELS} />} />
            <Route path='reset_password/:themeId?' element={<ResetPassword vertical={VERTICALS.HOTELS} />} />
          </Route>
          <Route path={VERTICALS.RESTAURANTS}>
            <Route path='login/:themeId?' element={<Login vertical={VERTICALS.RESTAURANTS} />} />
            <Route path='forgot_password/:themeId?' element={<ForgotPassword vertical={VERTICALS.RESTAURANTS} />} />
            <Route path='reset_password/:themeId?' element={<ResetPassword vertical={VERTICALS.RESTAURANTS} />} />
          </Route>
          <Route path='*' element={<Navigate to={`/auth/${config.vertical}/login`} replace />} />
        </Route>
        <Route path='trainings/*'>
          <Route path='vog' element={<VogTrainings />} />
        </Route>
        <Route path='cxp/*'>
          <Route path='cxp_onboarding' element={<CXPSetPassword />} />
          <Route path='cxp_already_upgraded' element={<CXPAlreadyUpgraded />} />
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path='*' element={<Tyra />} />
        </Route>
      </Routes>
    </HashRouter>
  </ContextsProvider>
);

export default App;
